<template>
  <div class="organization">
    <div class="bottom">
      <div class="con">
          <div class="content" @click="getImages(index)" v-for="(item,index) in dataList" :key="index">
            <img class="img_l" src="@/assets/images/organization/left.png" alt="">
            <span> {{item}}</span>
            <img class="img_r" src="@/assets/images/organization/right.png" alt="">
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      dataList:[
        "主席团组织架构",
        "第一代表队组织架构",
        "第二代表队组织架构",
        "第三代表队组织架构",
        "第四代表队组织架构",
        "第五代表队组织架构",
        "第六代表队组织架构",
      ]
    }
  },
  methods:{
    getImages(index){
       this.$router.push({
                name: "organizationImages",
                params: {
                    index: index,
                },
        });
    }
  }
}
</script>

<style scoped lang="less">
  .organization{
    width: 100%;
    height: 100%;
    padding: 4.2rem 0 0 0; 
    background-color:#B7D7FF;
    background:url('../../../assets/images/survey/top_bg.png') no-repeat;
    background-size: 100% 100%;
    .bottom{
      width: 100%;
      height:11rem;
      background-color:#B7D7FF;
      position: relative;
      .con{
        width: 100%;
        // height: 10rem;
        // background: red;
        position:absolute;
        left: 0;
        top: -1.4rem;
        .content{
          width: 90%;
          margin-left: 5%;
          height: 1.4rem;
          background: #FFFFFF;
          margin-bottom: 0.2rem;
          border-radius: 0.1rem;
          font-weight: 700;
          color: #333333;
          position: relative;
          .img_l{
            position: absolute;
            left: 0.6rem;
            top: 0.5rem;
          }
          span{
            position: absolute;
            left: 1.6rem;
            top: 0.5rem;
          }
          .img_r{
            position: absolute;
            right: 0.3rem;
            top: 0.5rem;
          }
        }
      }
    }
  }
</style>